var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-2 d-flex align-center",attrs:{"width":"294","height":"70","elevation":"0"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newTask.task_name),expression:"newTask.task_name"}],staticClass:"mt-1 ml-1",staticStyle:{"width":"180px"},attrs:{"autofocus":"","flat":"","solo":"","placeholder":"输入任务名称'回车'保存","dense":""},domProps:{"value":(_vm.newTask.task_name)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.saveTask.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.newTask, "task_name", $event.target.value)}}}),_c('date-range-picker',{ref:"picker",attrs:{"opens":"center","control-container-class":"text-caption","locale-data":{ 
                                  firstDay: 1,
                                  format: 'yyyy-mm-dd',
                                  applyLabel: '确定',
                                  cancelLabel: '取消',
                                  daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                                  monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                              },"minDate":_vm.minDate,"maxDate":_vm.maxDate,"dateRange":{startDate: _vm.newTask.task_start_date, endDate: _vm.newTask.task_end_date},"ranges":false},on:{"update":_vm.changeTaskDate},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_c('div',{staticStyle:{"cursor":"pointer"}},[_c('v-icon',[_vm._v("mdi-calendar-month-outline")])],1)]}}])}),_c('v-menu',{attrs:{"offset-y":"","nudge-width":50},scopedSlots:_vm._u([{key:"activator",fn:function({attrs, on}){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-n8",attrs:{"color":_vm.priority[_vm.newTask.task_priority-1].color}},'v-icon',attrs,false),on),[_vm._v("mdi-numeric-"+_vm._s(_vm.newTask.task_priority)+"-circle-outline")])]}}])},[_c('v-card',_vm._l(([1,2,3,4,5]),function(i){return _c('v-btn',{key:i,staticClass:"justify-start",attrs:{"color":_vm.priority[i-1].color,"block":"","text":""},on:{"click":function($event){_vm.newTask.task_priority=i}}},[_c('v-icon',[_vm._v("mdi-numeric-"+_vm._s(i)+"-circle-outline")]),_c('span',[_vm._v(_vm._s(_vm.priority[i-1].text))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({attrs, on}){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 text-caption",attrs:{"elevation":"0","color":"green","fab":"","x-small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm._f("surename")(_vm.newTask.task_owner)))])]}}])},[_c('UserPicker',{on:{"pick":_vm.changeTaskOwner}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }